import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Content, { HTMLContent } from "../components/Content";

// components
import CSButton from "../components/CSButton";

// services
import { goToCounselingForm } from "../services/counselingLinks";

// share
import SharePlacesPageTemplate from "./share-places-page";
import SharePickupNewsPageTemplate from "./share-pickup-news-page";
import ShareHistoryPageTemplate from "./share-history-page";
import ShareInfomationPageTemplate from "./share-infomation-page";

// images
import KeyvisualImage from "../img/medical_hair/image_keyvisual.png";
import Image001 from "../img/medical_hair/image_001.svg";
import Image002 from "../img/medical_hair/image_002.svg";
import Image003 from "../img/medical_hair/image_003.svg";
import MeritImage001 from "../img/medical_hair/bg_image_001.png";
import MeritImage002 from "../img/medical_hair/bg_image_002.png";
import MeritImage003 from "../img/medical_hair/bg_image_003.png";
import MeritImage004 from "../img/medical_hair/bg_image_004.png";
import InterviewImage from "../img/medical_hair/image_Interview.png";
import GroupsImage from "../img/medical_hair/image_groups.svg";
import MachineImage from "../img/medical_hair/image_machine.svg";
import PaymentImage from "../img/medical_hair/image_payment.svg";
import MultiImage from "../img/share/image_multi.png";
import AboutImage from "../img/top/about.png";
import FullImage from "../img/top/image_full.jpg";

// pdfs
import aboutHairLossPDF from "../pdfs/medical_treatment/about_hair_loss.pdf";

const onPressToMoveScreenWithQuery = (hash) => {
  navigate(`/medical_hair/price${hash}`);
};

export const MedicalhairPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link to={"/"} className="c-bread__link">
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">医療脱毛</div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2">
        <div className="l-bg-cross-in-layout__outer">
          <div className="l-bg-cross-in-layout">
            <div className="l-bg-cross-in-layout__inner">
              <div className="c-pickup-card">
                <div className="c-pickup-card__item">
                  <div className="c-pickup-card__image">
                    <img src={KeyvisualImage} alt="" />
                  </div>
                </div>
                <div className="c-pickup-card__item">
                  <h2 className="c-pickup-card__title">
                    <span>”結果”に導く</span>、医療脱毛
                  </h2>
                  <p className="c-pickup-card__description">
                    当院は、
                    <span className="c-yellow-maker">
                      皮膚の専門知識を持った皮膚科専門医
                    </span>
                    が、厚生労働省認可の脱毛機を使用して処置を行います。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="l-section-lv2 l-main-content-hero">
          <div className="l-main-content-hero__inner">
            <div className="c-box-title__outer">
              <div className="c-box-title">
                <h2 className="c-box-title__text">-MERIT-</h2>
                <p className="c-box-title__description">医療脱毛のメリット</p>
              </div>
            </div>

            <section className="l-section-lv3 u-pc-mt60">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-MERIT 01-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={MeritImage001} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          医療従事者による施術
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      皮膚科専門医による問診、日本脱毛学会学会員の看護師による脱毛だから安心です。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* <section>
              <div className="c-bg-image-media -bg001">
                <div className="c-bg-image-media__inner">
                  <div className="c-bg-image-media__box">
                    <div className="c-simple-box">
                      <div className="c-simple-box__inner">
                        <p className="c-simple-box__label">-MERIT 01-</p>
                        <h3 className="c-simple-box__title">
                          医療従事者による施術
                        </h3>
                        <p className="c-simple-box__description">
                          皮膚科専門医による問診、日本脱毛学会学会員の看護師による脱毛だから安心です。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="l-section-lv3">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 02-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={MeritImage004} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          肌質に合わせてレーザーを選択
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      患者様のお肌の状態を医療従事者が確認し、患者様のお肌にあった医療レーザーを選択します。
                    </p>
                    <div className="c-other-pickup-card__button">
                      <a
                        className="c-button -sp-full"
                        href={aboutHairLossPDF}
                        target="_blank"
                      >
                        <span className="c-button__text">詳しく見る</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="u-pc-mt100 u-sp-mt60">
              <div className="c-bg-image-media -bg004 -right-box">
                <div className="c-bg-image-media__inner">
                  <div className="c-bg-image-media__box">
                    <div className="c-simple-box">
                      <div className="c-simple-box__inner">
                        <p className="c-simple-box__label">-MERIT 02-</p>
                        <h3 className="c-simple-box__title">
                          肌質に合わせてレーザーを選択　
                        </h3>
                        <p className="c-simple-box__description">
                          患者様のお肌の状態を医療従事者が確認し、患者様のお肌にあった医療レーザーを選択します。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="l-section-lv3">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 03-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={MeritImage003} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          麻酔クリームで痛み軽減
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      痛みが心配な方には、麻酔クリームをご用意しておりますのでお気軽にスタッフまでお申し付けくださいませ。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="u-pc-mt100 u-sp-mt60">
              <div className="c-bg-image-media -bg003">
                <div className="c-bg-image-media__inner">
                  <div className="c-bg-image-media__box">
                    <div className="c-simple-box">
                      <div className="c-simple-box__inner">
                        <p className="c-simple-box__label">-MERIT 03-</p>
                        <h3 className="c-simple-box__title">
                          麻酔クリームで痛み軽減
                        </h3>
                        <p className="c-simple-box__description">
                          痛みが心配な方には、麻酔クリームをご用意しておりますのでお気軽にスタッフまでお申し付けくださいませ。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>
        </section>

        <section className="l-section-lv2 l-main-content-hero">
          <div className="l-main-content-hero__inner">
            <div className="c-box-title__outer">
              <div className="c-box-title">
                <h2 className="c-box-title__text">-POINT-</h2>
                <p className="c-box-title__description">当店の魅力</p>
              </div>
            </div>

            <p className="c-copy-text">
              当院はお客様に寄り添って、
              <span className="c-yellow-maker">安心して通える、環境創り</span>
              を行っています
            </p>

            <section className="l-section-lv3 u-pc-mt60">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 01-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={InterviewImage} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          お肌の専門『皮膚科』による脱毛
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      医療脱毛は皮膚科専門医以外の医師でも問診が可能となっています。しかし直接『お肌に触れるもの』だからこそ、当院ではお肌の専門知識を持った皮膚科専門医による問診となります。万が一、肌トラブルが起きた際でも迅速に対応させていただきます。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv3">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 02-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={GroupsImage} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          福岡県内に５院のグループ
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      福岡県内に姉妹院５院あるため、『平日は天神皮ふ科・休日は博多皮ふ科』など患者様の都合に合わせて予約をお取りいただけます。また、万が一ご希望の日時に予約が取れなくても姉妹院が空いていればご案内可能です。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv3">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 03-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={MachineImage} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          厚生労働省認可の医療脱毛機で安心
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      医療脱毛が身近になった昨今、様々な医療脱毛機が登場しています。当院では、患者様に安心していただけるよう厚生労働省認可の医療脱毛機を採用、『質の高い脱毛』を患者様に提供できるよう高品質の機械にこだわりを持っています。もちろん施術の途中で機械を変更することはありません。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv3">
              <div className="c-point-media">
                <p className="c-point-media__outer-text">-POINT 04-</p>
                <div className="c-point-media__inner">
                  <div className="c-point-media__col">
                    <div className="c-point-media__image">
                      <img src={PaymentImage} alt="" />
                    </div>
                  </div>
                  <div className="c-point-media__col">
                    <div className="c-point-media__title">
                      <h3 className="c-point-media__title__main-text">
                        <span className="c-yellow-maker">
                          お好きな料金プラン、支払い方法からお選びいただけます
                        </span>
                      </h3>
                    </div>
                    <p className="c-point-media__description">
                      当院では、全身脱毛はもちろんお好きな部位を自由に組み合わせて脱毛を行えます。脱毛回数も1回料金(都度払い)から、4回コース・8回コース・12回コースをご用意しておりますので、患者様一人ひとりのご要望にあった脱毛が可能です。お支払いは、現金払いクレジットカード払い、QRコード決済(PayPay)、医療ローンからお選びいただけます。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <div className="c-full-capture">
              <img src={MultiImage} alt="" />
            </div>
          </div>
        </section>

        <section className="l-section-lv2 l-main-content-hero">
          <div className="l-main-content-hero__inner">
            <div className="c-box-title__outer">
              <div className="c-box-title">
                <h2 className="c-box-title__text">-FLOW-</h2>
                <p className="c-box-title__description">治療の流れ</p>
              </div>
            </div>

            <div className="c-flow-treatment -cetner-contents">
              <div className="c-flow-treatment__inner">
                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">
                    クレンジング・洗顔（顔脱毛の方のみ）
                  </div>
                  <p className="c-flow-treatment__description">
                    当院にクレンジング剤・洗顔料をご用意しておりますので、ご自由にお使いください。
                  </p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">医師による問診</div>
                  <p className="c-flow-treatment__description">初回のみ</p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">カウンセリング</div>
                  <p className="c-flow-treatment__description">初回のみ</p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">（麻酔）</div>
                  <p className="c-flow-treatment__description">希望者のみ</p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">施術</div>
                  <p className="c-flow-treatment__description">レーザー照射</p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">（お化粧）</div>
                  <p className="c-flow-treatment__description">
                    一部化粧品をご用意しておりますが、お化粧直しの道具をご持参ください。
                  </p>
                  <div className="c-flow-treatment__circle"></div>
                </div>

                <div className="c-flow-treatment__item">
                  <div className="c-flow-treatment__title">お会計</div>
                  <p className="c-flow-treatment__description">
                    現金、クレジット、QR決済(PayPay)でのお支払いが可能です。
                  </p>
                  <div className="c-flow-treatment__circle"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="l-section-lv2 l-main-content-hero">
          <div className="l-main-content-hero__inner">
            <div className="c-box-title__outer">
              <div className="c-box-title">
                <h2 className="c-box-title__text">-PRICE-</h2>
                <p className="c-box-title__description">料金表</p>
              </div>
            </div>

            <p className="c-copy-text">
              当院はお客様に寄り添って、
              <span className="c-yellow-maker">安心して通える、環境創り</span>
              を行っています
            </p>

            <section className="l-section-lv3">
              <div className="c-label-box-tile">
                <div className="c-label-box-tile__inner">
                  <h3 className="c-label-box-tile__title">-部位別料金表-</h3>
                  <div className="c-label-box-tile__list">
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-face-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">顔</span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-neck-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">首</span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-body-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">わき</span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-arm-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          腕、手
                        </span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-leg-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          脚、足
                        </span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-body-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">胴体</span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery(
                          "#female-delicate-zoon-price"
                        )
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          デリケートゾーン
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="c-small-text u-pc-mt40 u-sp-mt30">医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は。</p> */}
            </section>

            <section className="l-section-lv3">
              <div className="c-label-box-tile">
                <div className="c-label-box-tile__inner">
                  <h3 className="c-label-box-tile__title">-セットメニュー-</h3>
                  <div className="c-label-box-tile__list">
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery(
                          "#female-basic-women-price"
                        )
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          基本女子
                        </span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery(
                          "#female-adult-women-price"
                        )
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          大人女子
                        </span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery(
                          "#female-limbs-perfect-women-price"
                        )
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          手足パーフェクトセット
                        </span>
                      </div>
                    </div>
                    <div
                      className="c-label-box-tile__list__item c-border-label"
                      onClick={() =>
                        onPressToMoveScreenWithQuery("#female-whole-body-price")
                      }
                    >
                      <div className="c-border-label__link">
                        <span className="c-border-label__link__text">
                          全身脱毛
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="c-small-text u-pc-mt40 u-sp-mt30">医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は。</p> */}
            </section>

            <div className="u-txt-c u-pc-mt80 u-sp-mt40">
              <div
                className="c-right-arrow-button -border-button"
                onClick={() => onPressToMoveScreenWithQuery("")}
              >
                <div className="c-right-arrow-button__text">料金表一覧</div>
              </div>
            </div>

            <CSButton
              text="無料カウンセリングを申し込む"
              onClick={() =>
                goToCounselingForm({
                  urlParams: "?tenjin-hifuka_hp&from_shop=tenjin-hifuka",
                })
              }
            />
          </div>
        </section>
      </section>
    </>
  );
};

MedicalhairPageTemplate.propTypes = {
  title: PropTypes.string,
};

const MedicalhairPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO
        title="【医療脱毛・全身脱毛】福岡天神で医療脱毛するなら天神皮ふ科"
        description="福岡の天神皮ふ科では、患者様の肌質に合わせた全身脱毛・医療脱毛の施術を行っております。問診～施術まで皮膚科専門医が担当し、質の高い脱毛をご提供するため厚生労働省認可の医療脱毛機を使用していますので、安心して施術を受けていただけます。"
        keywords="福岡市,天神,皮膚科,天神皮ふ科,脱毛,美容診療"
      />
      <MedicalhairPageTemplate title={post.frontmatter.title} />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

MedicalhairPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default MedicalhairPage;

export const medicalhairPageQuery = graphql`
  query MedicalhairPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
